<template>
    <o-data-lookup :data-object="dsDO" :noClear="true">
        <template #target="{ target }">
            <component v-if="is" :is="is" :ref="target" v-bind="$attrs" :value="textInputValue"></component>
            <input v-else-if="textInput" :ref="target" v-bind="$attrs" :value="textInputValue">
        </template>
        <o-column field="ID" width="100"></o-column>
        <o-column field="Name" width="200"></o-column> 
        <o-column field="CompetenceArea" width="200"></o-column> 
    </o-data-lookup>
</template>

<script setup>
import { getOrCreateDataObject } from 'o365.vue.ts'

const props = defineProps({
    textInput: Boolean,
    is: String,
    textInputValue: String,        
});

const dsDO = getOrCreateDataObject({
    id: 'dsDO' + crypto.randomUUID(),
    viewName: 'aviw_Resource_Expertise',
    maxRecords: 25,
    whereClause: "",
    loadRecents: false,
    distinctRows: true,
    fields: [
        { name: "ID", type: "string" },
        { name: "Name", type: "string" },
        { name: "CompetenceArea", type: "string" }
    ]
});
</script>
